@font-face {
	font-family: 'Computer Modern Sans';
	src: url('cmunss.eot');
	src: url('cmunss.eot?#iefix') format('embedded-opentype'),
		 url('cmunss.woff') format('woff'),
		 url('cmunss.ttf') format('truetype'),
		 url('cmunss.svg#cmunss') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Computer Modern Sans';
	src: url('cmunsx.eot');
	src: url('cmunsx.eot?#iefix') format('embedded-opentype'),
		 url('cmunsx.woff') format('woff'),
		 url('cmunsx.ttf') format('truetype'),
		 url('cmunsx.svg#cmunsx') format('svg');
	font-weight: bold;
	font-style: normal;
}


@font-face {
	font-family: 'Computer Modern Sans';
	src: url('cmunsi.eot');
	src: url('cmunsi.eot?#iefix') format('embedded-opentype'),
		 url('cmunsi.woff') format('woff'),
		 url('cmunsi.ttf') format('truetype'),
		 url('cmunsi.svg#cmunsi') format('svg');
	font-weight: normal;
	font-style: italic;
}


@font-face {
	font-family: 'Computer Modern Sans';
	src: url('cmunso.eot');
	src: url('cmunso.eot?#iefix') format('embedded-opentype'),
		 url('cmunso.woff') format('woff'),
		 url('cmunso.ttf') format('truetype'),
		 url('cmunso.svg#cmunso') format('svg');
	font-weight: bold;
	font-style: italic;
}

