:root {
  --orange:#de9526;
  --green:#25bf4a;
  --gray:#4d4d4d;
  --white: #FFFFFF;
}

body {
  margin: 0;
  background-image: url('./background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

a {
  font-weight: bold;
  color: var(--orange);
  text-decoration: underline;
}

a:hover {
  color: var(--green);
}

.highlighted-text {
  font-weight: bold;
}